import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

// Services
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

/**
 * Interceptor that handles HTTP errors and displays notifications.
 */
@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NzNotificationService,
    private _router: Router,
    private _authService: AuthService
  ) {}

  /**
   * Intercepts the HTTP request and handles any errors that occur.
   * @param request - The HTTP request.
   * @param next - The next HTTP handler in the chain.
   * @returns An observable of the HTTP event.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          if (error.error?.message) {
            errorMsg = error.error.message;
          } else {
            errorMsg = `Error Code: ${error.status}, Message: ${error.message}`;
          }

          // If the error is a 400 and there is no message, redirect to not-found
          if (error.status === 400 && !error.error?.message) {
            this._router.navigate(['/not-found']);
          }

          if (error.status === 401) {
            this._authService.logout();
          }
        }
        this.notificationService.error('Error', errorMsg);
        console.log(errorMsg);
        return throwError(() => new Error(errorMsg));
      }),
    );
  }
}