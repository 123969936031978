import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SendingRequestLoaderComponent } from './components/sending-request-loader/sending-request-loader.component';

// UI modules
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { AbilityModule } from '@casl/angular';
import { DividerComponent } from './components/divider/divider.component';

const MODULES = [
  CommonModule,
  ScrollingModule,
  FormsModule,
  RouterModule,
  SendingRequestLoaderComponent,
  NzTypographyModule,
  NzAlertModule,
  NzMessageModule,
  NzLayoutModule,
  NzSpaceModule,
  NzPageHeaderModule,
  NzBreadCrumbModule,
  NzInputModule,
  NzButtonModule,
  NzIconModule,
  NzModalModule,
  NzFormModule,
  NzSelectModule,
  NzCheckboxModule,
  NzAutocompleteModule,
  NzDropDownModule,
  NzDrawerModule,
  NzSpinModule,
  NzProgressModule,
  NzStepsModule,
  NzDividerModule,
  NzFlexModule,
  NzSwitchModule,
  NzNotificationModule,
  NzToolTipModule,
  NzBadgeModule,
  NzTagModule,
  NzStatisticModule,
  NzCardModule,
  NzSkeletonModule,
  NzTabsModule,
  NzSegmentedModule,
  NzListModule,
  NzAvatarModule,
  NzDescriptionsModule,
  NzDatePickerModule,
  NzTimePickerModule,
  NzInputNumberModule,
  NzAffixModule,
  NzPopoverModule,
  NzCalendarModule,
  NzUploadModule,
  NzPopconfirmModule,
  NzEmptyModule,
  NzColorPickerModule,
  PageContainerComponent,
  AbilityModule,
  DividerComponent,
];

@NgModule({
  declarations: [],
  imports: [...MODULES],
  exports: [...MODULES],
})
export class SharedModule {}
